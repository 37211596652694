// import './App.css';
// import './Responsive.css';
// import './loader';

import React, { useEffect, useState } from "react";
import { images } from "../constants";
import { IoIosArrowDown } from "react-icons/io";

import {
  Header,
  About,
  // Brands,
  // Stats,
  // Portfolio,
  // Services,
  // Media,
  // Team,
  // AddServices,
  Statement,
  Stats,
  Statement2,
  TextMedia,
  TextMedia2,
  // Footer
} from "../container";
// import About from '../container/About/About';
import videoBgMp4 from "../assets/medical.mp4";
import { Helmet } from "react-helmet";
import { client, urlFor } from "../client";
import { NavLink, useParams } from "react-router-dom";

// import {Navbar} from '../components'

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

// import { Document, Page } from 'react-pdf';

import FacialTreatmentCCForm from "../assets/facial-treatment_client-consent-form.pdf";

import axios from "axios";
import fileDownload from "js-file-download";

import FacialTreatmentClientConsentForm from "../assets/Facial - Client Consent Form.pdf";
import FacialTeatmentConsentForm from "../assets/Facial Treatment - Consultation Form.pdf";
import MicroneedlingClientConsentForm from "../assets/Microneedling - Client Consent Form.pdf";
import MicroneedlingConsultationForm from "../assets/Microneedling - Consultation Form.pdf";
import MicrodermabrasionClientConsentForm from "../assets/Microdermabrasion - Client Consent Form.pdf";
import MicrodermabrasionConsultationForm from "../assets/Microdermabrasion - Consultation Form.pdf";
import RadioFrequencyClientConsentForm from "../assets/Radiofrequency Skin Tightening - Client Consent Form.pdf";
import RadioFrequencyConsultationForm from "../assets/Radio Frequency Skin Tightening - Consultation Form.pdf";
import LEDLightTherapyClientConsentForm from "../assets/LED Light Therapy - Client Consent Form.pdf";
import LEDLightTherapyConsultationForm from "../assets/LED Light Therapy - Consultation Form.pdf";
import ChemicalPeelClientConsentForm from "../assets/Chemical Peel - Client Consent Form.pdf";
import ChemicalPeelConsultationForm from "../assets/Chemical Peel - Consultation Form.pdf";
import NanofusionClientConsentForm from "../assets/Nanofusion - Client Consent Form.pdf";
import NanofusionConsultationForm from "../assets/Nanofusion - Consultation Form.pdf";
import Covid19LiabilityWaiver from "../assets/Covid-19 Liability Waiver and Release Form.pdf";
import CancellationPolicy from "../assets/Cancellation Policy.pdf";
import PhotoVideoReleaseForm from "../assets/Photo and Video Release Form.pdf";
import PatchTestConsentForm from "../assets/Patch Test Consent Form.pdf";

const handleDownload = (url, filename) => {
  axios
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, filename);
    });
};

const Forms = () => {
  const [notices, setNotices] = useState(null);
  // const [social, setSocial] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    client
      .fetch(
        `*[_type == "notices" && slug.current == 'notices']|order(orderRank){
            name,
            _id,
            slug,
            heading,
            description,
        }`
      )
      .then((data) => setNotices(data[0]))
      .catch(console.error);
  }, [slug]);

  // if (!notices)
  //   return (
  //     <div className="preloader">
  //       <div className="status"></div>
  //     </div>
  //   );

  return (
    <>
      <Helmet>
        <title>Forms</title>
        <meta name="description" content="Persephoney Aesthetics" />
        <meta name="keywords" content="Persephoney Aesthetics" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="%PUBLIC_URL%/logo-dark.png"
        />
      </Helmet>
      <section id="home-4">
        <video
          className="home-bg-video"
          src={videoBgMp4}
          autoPlay
          loop
          muted
          playsInline
        ></video>
        <div id="home-overlay-2"></div>
      </section>

      <div className="map-color-3">
        <div className="row" style={{ marginRight: "0" }}>
          <div className="home-headings tools-p-align">
            <div className="horizontal-heading influencer-name main-section-title">
              <h1 className="home-headings-2 influencer-name-h1">
                <span className="influencer-name-span">
                  {/* {about.name} */}
                  {/* {notices.name} */}
                  Forms
                </span>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <section
        id="about"
        className="main-section-content"
        style={{
          minHeight: "calc(100vh - 300px)",
          display: "table",
          width: "100%",
          paddingTop: "60px",
        }}
      >
        <div
          className="about-01 sectionBg1"
          style={{
            display: "table-cell",
            verticalAlign: "middle",
            backgroundColor: "white",
          }}
        >
          <div className="container">
            <div
              className="row col-mobile-top"
              style={{ justifyContent: "center" }}
            >
              <div
                className="col-md-12 col-sm-12 col-mobile styled-divider"
                style={{
                  display: "table",
                  paddingBottom: "60px",
                  paddingTop: "30px",
                  borderStyle: "dashed",
                  maxWidth: "500px",
                }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">Upload Forms</h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "200px", margin: "30px auto" }}
                    />
                    <div className="text-style2">
                      {/* <p className='styled-p'>
                                        <ReactMarkdown 
                                                children={notices.description} 
                                                remarkPlugins={[remarkGfm]}   
                                                /> 
                                        </p> */}

                      <div className="home-btn">
                        <a
                          className="btn btn-general btn-home smooth-scroll"
                          href="https://www.dropbox.com/request/QCsyytTsYd4U3w9Okl8h"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Click Here to Upload
                        </a>
                      </div>
                    </div>
                    {/* <hr className='styled-divider' style={{maxWidth: '700px', margin: '30px auto'}}/> */}
                  </div>
                </div>
              </div>



              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

              <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{
                  display: "table",
                  marginBottom: "60px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">PDF Editer</h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2 text-style-form-edit" style={{maxWidth: '360px'}}>
                      {/* <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={FacialTreatmentClientConsentForm}
                          target="_blank"
                        >
                          Facial Treatment - Client Consent Form
                        </NavLink>
                      </div> */}

                      {/* <div className="home-btn" style={{ marginTop: "25px" }}>
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={FacialTeatmentConsentForm}
                          target="_blank"
                        >
                          Facial Treatment - Consultation Form
                        </NavLink>
                      </div> */}

                      Having trouble editing your forms? Some browsers won't allow
                  you to edit. Simply download the form from your browser and
                  use our PDF editer. Powered by <b>Dropbox.</b>
                    </div>

                    <div className="home-btn" style={{ textAlign: "center", paddingTop: '20px' }}>
                <a
                  className="btn btn-general btn-home smooth-scroll"
                  href="https://www.dropbox.com/tools/pdf/edit"
                  target="_blank"
                  rel="noreferrer"
                >
                  PDF Editer
                </a>
              </div>
                    
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                  </div>
                </div>
              </div>

              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

              <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{
                  display: "table",
                  marginBottom: "60px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">Facial Treatment Forms</h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">
                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={FacialTreatmentClientConsentForm}
                          target="_blank"
                        >
                          Facial Treatment - Client Consent Form
                        </NavLink>
                      </div>

                      <div className="home-btn" style={{ marginTop: "25px" }}>
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={FacialTeatmentConsentForm}
                          target="_blank"
                        >
                          Facial Treatment - Consultation Form
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{ display: "table", marginBottom: "60px" }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">
                      Facial Treatment - Consultation Form
                    </h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">
   

                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={FacialTeatmentConsentForm}
                          target="_blank"
                        >
                          Facial Treatment - Consultation Form
                        </NavLink>
                      </div>


                    </div>
                  </div>
                </div>


              </div> */}

              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

              <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{
                  display: "table",
                  marginBottom: "60px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">Microneedling Forms</h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">
                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={MicroneedlingClientConsentForm}
                          target="_blank"
                        >
                          Microneedling - Client Consent Form
                        </NavLink>
                      </div>

                      <div className="home-btn" style={{ marginTop: "25px" }}>
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={MicroneedlingConsultationForm}
                          target="_blank"
                        >
                          Microneedling - Consultation Form
                        </NavLink>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>

              {/* <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{ display: "table", marginBottom: "60px" }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">
                      Microneedling - Consultation Form
                    </h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">


                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={MicroneedlingConsultationForm}
                          target="_blank"
                        >
                          Microneedling - Consultation Form
                        </NavLink>
                      </div>


                    </div>
                  </div>
                </div>


              </div> */}

              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

              <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{
                  display: "table",
                  marginBottom: "60px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">Microdermabrasion Forms</h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">
                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={MicrodermabrasionClientConsentForm}
                          target="_blank"
                        >
                          Microdermabrasion - Client Consent Form
                        </NavLink>
                      </div>

                      <div className="home-btn" style={{ marginTop: "25px" }}>
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={MicrodermabrasionConsultationForm}
                          target="_blank"
                        >
                          Microdermabrasion - Consultation Form
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{ display: "table", marginBottom: "60px" }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">
                      Microdermabrasion - Consultation Form
                    </h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">


                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={MicrodermabrasionConsultationForm}
                          target="_blank"
                        >
                          Microdermabrasion - Consultation Form
                        </NavLink>
                      </div>


                    </div>
                  </div>
                </div>

 
              </div> */}

              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

              <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{
                  display: "table",
                  marginBottom: "60px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">High Frequency Forms</h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">
                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={RadioFrequencyClientConsentForm}
                          target="_blank"
                        >
                          High Frequency - Client Consent Form
                        </NavLink>
                      </div>

                      <div className="home-btn" style={{ marginTop: "25px" }}>
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={RadioFrequencyConsultationForm}
                          target="_blank"
                        >
                          High Frequency - Consultation Form
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{ display: "table", marginBottom: "60px" }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">
                      High Frequency - Consultation Form
                    </h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">


                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={RadioFrequencyConsultationForm}
                          target="_blank"
                        >
                          High Frequency - Consultation Form
                        </NavLink>
                      </div>


                    </div>
                  </div>
                </div>


              </div> */}

              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

              <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{
                  display: "table",
                  marginBottom: "60px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">LED Light Therapy Forms</h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">
                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={LEDLightTherapyClientConsentForm}
                          target="_blank"
                        >
                          LED Light Therapy - Client Consent Form
                        </NavLink>
                      </div>

                      <div className="home-btn" style={{ marginTop: "25px" }}>
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={LEDLightTherapyConsultationForm}
                          target="_blank"
                        >
                          LED Light Therapy - Consultation Form
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{ display: "table", marginBottom: "60px" }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">
                      LED Light Therapy - Consultation
                    </h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">



                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={LEDLightTherapyConsultationForm}
                          target="_blank"

                        >
                          LED Light Therapy - Consultation Form
                        </NavLink>
                      </div>


                    </div>

                  </div>
                </div>



              </div> */}

              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

              <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{
                  display: "table",
                  marginBottom: "60px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">Chemical Peel Forms</h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">
                      {/* <p className='styled-p'>
            <ReactMarkdown 
                children={notices.description} 
                remarkPlugins={[remarkGfm]}   
                /> 
            </p> */}

                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={ChemicalPeelClientConsentForm}
                          target="_blank"
                        >
                          Chemical Peel - Client Consent Form
                        </NavLink>
                      </div>

                      <div
                        className="text-style2"
                        style={{ marginTop: "25px" }}
                      >
                        <div className="home-btn">
                          <NavLink
                            className="btn btn-general btn-home smooth-scroll"
                            to={ChemicalPeelConsultationForm}
                            target="_blank"
                          >
                            Chemical Peel - Consultation Form
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{ display: "table", marginBottom: "60px" }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">
                      Chemical Peel - Consultation
                    </h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />

                    <div className="text-style2">
                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={ChemicalPeelConsultationForm}
                          target="_blank"
                        >
                          Chemical Peel - Consultation Form
                        </NavLink>
                      </div>
                    </div>



                  </div>
                </div>


              </div> */}

              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

              <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{
                  display: "table",
                  marginBottom: "60px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">Nanofusion Forms</h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">
                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={NanofusionClientConsentForm}
                          target="_blank"
                          // download="Chemical Peel - Client Consent Form"
                        >
                          Nanofusion - Client Consent Form
                        </NavLink>
                      </div>
                    </div>

                    <div className="text-style2" style={{ marginTop: "25px" }}>
                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={NanofusionConsultationForm}
                          target="_blank"
                          // download="Nanofusion - Consultation Form"
                        >
                          Nanofusion - Consultation Form
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{ display: "table", marginBottom: "60px" }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">
                      Nanofusion - Consultation
                    </h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">
                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={NanofusionConsultationForm}
                          target="_blank"
                        >
                          Nanofusion - Consultation Form
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

              <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{
                  display: "table",
                  marginBottom: "60px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">
                      Covid-19 Liability Waiver & Release
                    </h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">
                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={Covid19LiabilityWaiver}
                          target="_blank"
                          // download="Covid 19 Liability Waiver & Release Form"
                        >
                          Covid-19 Liability Waiver & Release Form
                        </NavLink>
                      </div>
                    </div>
                    {/* <hr className='styled-divider' style={{maxWidth: '700px', margin: '30px auto'}}/> */}
                  </div>
                </div>

                {/* <Document file={FacialTreatmentCCForm}>
            <Page pageNumber={1} />
            </Document> */}
              </div>

              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

              <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{
                  display: "table",
                  marginBottom: "60px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">Cancellation Policy</h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">
                      {/* <p className='styled-p'>
            <ReactMarkdown 
                children={notices.description} 
                remarkPlugins={[remarkGfm]}   
                /> 
            </p> */}

                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={CancellationPolicy}
                          target="_blank"
                          // download="Cancellation Policy"
                        >
                          Cancellation Policy
                        </NavLink>
                      </div>
                    </div>
                    {/* <hr className='styled-divider' style={{maxWidth: '700px', margin: '30px auto'}}/> */}
                  </div>
                </div>

                {/* <Document file={FacialTreatmentCCForm}>
            <Page pageNumber={1} />
            </Document> */}
              </div>

              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

              <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{
                  display: "table",
                  marginBottom: "60px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">Photo & Video</h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">
                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={PhotoVideoReleaseForm}
                          target="_blank"
                          // download="Photo & Video Release Form"
                        >
                          Photo & Video Release Form
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <Document file={FacialTreatmentCCForm}>
            <Page pageNumber={1} />
            </Document> */}
              </div>

              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

              <div
                className="col-md-12 col-sm-12 col-mobile"
                style={{
                  display: "table",
                  marginBottom: "60px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">Patch Test</h1>
                    <hr
                      className="styled-divider"
                      style={{ maxWidth: "700px", margin: "30px auto" }}
                    />
                    <div className="text-style2">
                      <div className="home-btn">
                        <NavLink
                          className="btn btn-general btn-home smooth-scroll"
                          to={PatchTestConsentForm}
                          target="_blank"
                          // download="Patch Test Consent Form"
                        >
                          Patch Test Consent Form
                        </NavLink>
                      </div>
                    </div>
                    {/* <hr className='styled-divider' style={{maxWidth: '700px', margin: '30px auto'}}/> */}
                  </div>
                </div>

                {/* <Document file={FacialTreatmentCCForm}>
            <Page pageNumber={1} />
            </Document> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Portfolio/> */}

      {/* <Brands/> */}

      {/* <Services/> */}
      {/* <Statement/>
            <TextMedia/> */}

      {/* <About/> */}
      {/* <Stats/> */}
      {/* <TextMedia2/> */}

      <Statement2 />

      {/*<Media/>*/}
      {/*<Team/>*/}
      {/*<AddServices/>*/}
    </>
  );
};

export default Forms;
