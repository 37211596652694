// import './App.css';
// import './Responsive.css';
// import './loader';

import React, { useEffect, useState } from 'react';
import {images} from "../constants";
import { IoIosArrowDown } from 'react-icons/io';
import { client } from '../client';

import {
    Header,
    About,
    // Brands,
    // Stats,
    // Portfolio,
    // Services,
    // Media,
    // Team,
    // AddServices,
    Statement,
    Stats,
    Statement2,
    TextMedia,
    TextMedia2,
    // Footer
} from '../container';
// import About from '../container/About/About';
import videoBgMp4 from '../assets/medical.mp4';
import { Helmet } from 'react-helmet';

// import {Navbar} from '../components'

const FAQ = () => {
    const [faq, setFaq] = useState(null);

    useEffect(() => {
        client.fetch(`*[_type == "faq"]|order(orderRank)`
        ).then((data) => {
            setFaq(data)
        })

    }, []);

    if(!faq) return (
        <div className="preloader">
            <div className="status"></div>
        </div>
    )

    return (
        <>
          <Helmet>
            <title>Frequently Asked Questions</title>
            <meta
                name='description'
                content='Persephoney Aesthetics'
            />
            <meta name='keywords' content='Persephoney Aesthetics'/>
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="%PUBLIC_URL%/logo-dark.png"
            />
        </Helmet>
            <section id="home-4">
                <video className="home-bg-video" src={videoBgMp4} autoPlay loop muted playsInline>
                </video>
                <div id="home-overlay-2"></div>
            </section>

            <div className="map-color-3" >
                <div className="row" style={{marginRight: '0'}}>
                    <div className="home-headings tools-p-align">
                        <div className="horizontal-heading influencer-name main-section-title">
                            <h1 className="home-headings-2 influencer-name-h1"><span className='influencer-name-span'> 
                            {/* {about.name} */}
                            FAQ
                            </span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <section id="about" className='main-section-content'  style={{height: '900px', display: 'table', width: '100%'}}>
                <div className="about-01 sectionBg1" style={{display: 'table-cell', verticalAlign: 'middle', backgroundColor: 'white'}}>
                    <div className="container">
                    <div className="row col-mobile-top" style={{justifyContent: 'center'}}>
                    <div className="col-md-12">
                            <div className="tech-statement text-center">
                                <h1 className='styled-heading'>Frequently Asked Questions</h1>
                                <hr className='styled-divider'/>

                                {faq.map((faq, index) => (
                                    <>
                                    <div className='text-style'
                                    key={faq.name + index}
                                    >
                                    <p className='styled-p' style={{fontWeight: '800', color: '#563E1E'}}>{faq.title} </p>
                                    <p className='styled-p'>{faq.description}</p>

                                    </div>
                                    <hr className='styled-divider'/>
                                    </>
       
                                
                            
                                 ))}
                                  
                            </div>
                        </div>
                            

                        </div>
                    </div>
                </div>



            </section>
            


            {/* <Portfolio/> */}

            {/* <Brands/> */}



            {/* <Services/> */}
            {/* <Statement/>
            <TextMedia/> */}

            {/* <About/> */}
            {/* <Stats/> */}
            {/* <TextMedia2/> */}
 
            <Statement2/>
        
            {/*<Media/>*/}
            {/*<Team/>*/}
            {/*<AddServices/>*/}


        </>
    );
}

export default FAQ;