// import './App.css';
// import './Responsive.css';
// import './loader';

import React, { useEffect, useState } from 'react';
import {images} from "../constants";
import { IoIosArrowDown } from 'react-icons/io';

import {
    Header,
    About,
    // Brands,
    // Stats,
    // Portfolio,
    // Services,
    // Media,
    // Team,
    // AddServices,
    Statement,
    Stats,
    Statement2,
    TextMedia,
    TextMedia2,
    // Footer
} from '../container';
// import About from '../container/About/About';
import videoBgMp4 from '../assets/medical.mp4';
import { Helmet } from 'react-helmet';
import { client, urlFor } from '../client';
import { useParams } from 'react-router-dom';

// import {Navbar} from '../components'


import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const Notice = () => {

    const [notices, setNotices] = useState(null);
    // const [social, setSocial] = useState(null);
    const {slug} = useParams();




    useEffect(() => {
        client.fetch(`*[_type == "notices" && slug.current == 'notices']|order(orderRank){
            name,
            _id,
            slug,
            heading,
            description,
        }`).then((data) => setNotices(data[0]))
            .catch(console.error)
    }, [slug]);


    if(!notices) return (
        <div className="preloader">
            <div className="status"></div>
        </div>
    )

    return (
        <>
        <Helmet>
            <title>Notices</title>
            <meta
                name='description'
                content='Persephoney Aesthetics'
            />
            <meta name='keywords' content='Persephoney Aesthetics'/>
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="%PUBLIC_URL%/logo-dark.png"
            />
        </Helmet>
            <section id="home-4">
                <video className="home-bg-video" src={videoBgMp4} autoPlay loop muted playsInline>
                </video>
                <div id="home-overlay-2"></div>
            </section>

            <div className="map-color-3" >
                <div className="row" style={{marginRight: '0'}}>
                    <div className="home-headings tools-p-align">
                        <div className="horizontal-heading influencer-name main-section-title">
                            <h1 className="home-headings-2 influencer-name-h1"><span className='influencer-name-span'> 
                            {/* {about.name} */}
                            {notices.name}
                            </span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <section id="about" className='main-section-content' style={{minHeight: 'calc(100vh - 300px)', display: 'table', width: '100%',paddingTop: '60px'}}>
                <div className="about-01 sectionBg1" style={{display: 'table-cell', verticalAlign: 'middle', backgroundColor: 'white'}}>
                    <div className="container">
                    <div className="row col-mobile-top" style={{justifyContent: 'center'}}>
                            {/* Left Side */}


                            {/* Right Side */}
                            {notices && (

                                <div className="col-md-12 col-sm-12 col-mobile" style={{display: 'table'}}>
                                    <div className="about-right" style={{display: 'table-cell', verticalAlign: 'middle'}}>
                                        <div className="horizontal-heading main-about" style={{color: '#000'}}>
                                        <h1 className='styled-heading2'>{notices.heading}</h1>
                                        <hr className='styled-divider' style={{maxWidth: '700px', margin: '30px auto'}}/>
                                            <div className='text-style2'>
                                            <p className='styled-p'>
                                            <ReactMarkdown 
                                                    children={notices.description} 
                                                    remarkPlugins={[remarkGfm]}   
                                                    /> 
                                            </p>
            
                                            </div>
                                        <hr className='styled-divider' style={{maxWidth: '700px', margin: '30px auto'}}/>
                                        </div>

                        

                                    </div>
                                </div>

                            )}

                        </div>
                    </div>
                </div>



            </section>
            


            {/* <Portfolio/> */}

            {/* <Brands/> */}



            {/* <Services/> */}
            {/* <Statement/>
            <TextMedia/> */}

            {/* <About/> */}
            {/* <Stats/> */}
            {/* <TextMedia2/> */}
 
            <Statement2/>
        
            {/*<Media/>*/}
            {/*<Team/>*/}
            {/*<AddServices/>*/}


        </>
    );
}

export default Notice;