// import './App.css';
// import './Responsive.css';
// import './loader';

import React, {useState, useEffect} from 'react';
import {images} from "../constants";
import { IoIosArrowDown } from 'react-icons/io';
import GoogleMapReact from 'google-map-react';


import {
    Header,
    About,
    // Brands,
    // Stats,
    // Portfolio,
    // Services,
    // Media,
    // Team,
    // AddServices,
    Statement,
    Stats,
    Statement2,
    TextMedia,
    TextMedia2,
    // Footer
} from '../container';
// import About from '../container/About/About';
import videoBgMp4 from '../assets/medical.mp4';
import { FaFacebookF, FaGooglePlus, FaInstagram, FaLinkedin, FaTiktok, FaYoutube } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

import {client, urlFor} from "../client";
import {Link, useParams} from "react-router-dom";

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
// import {Navbar} from 'Contact../components'

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Contact = () => {
    const [contact, setContact] = useState(null);
    const {slug} = useParams();

    const defaultProps = {
        center: {
          lat: 37.756900,
          lng: -122.252950
        },
        zoom: 11
    };



    useEffect(() => {
        client.fetch(`*[_type == "contact" && slug.current == 'contact']|order(orderRank){
            name,
            _id,
            preheading,
            heading,
            description,
            footerText,
            footerEmail,
            footerPhone,
        }`).then((data) => setContact(data[0]))
            .catch(console.error)
    }, [slug]);


    if(!contact) return (
        <div className="preloader">
            <div className="status"></div>
        </div>
    )


    return (
        <>
        <Helmet>
            <title>Contact</title>
            <meta
                name='description'
                content='Persephoney Aesthetics'
            />
            <meta name='keywords' content='Persephoney Aesthetics'/>
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="%PUBLIC_URL%/logo-dark.png"
            />
        </Helmet>
            <section id="home-4">
                <video className="home-bg-video" src={videoBgMp4} autoPlay loop muted playsInline>
                </video>
                <div id="home-overlay-2"></div>
            </section>

            <div className="map-color-3" >
                <div className="row" style={{marginRight: '0'}}>
                    <div className="home-headings tools-p-align">
                        <div className="horizontal-heading influencer-name main-section-title">
                            <h1 className="home-headings-2 influencer-name-h1"><span className='influencer-name-span'> 
                            {/* {about.name} */}
                            Contact
                            </span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <section id="about" className='main-section-content' style={{height: 'calc(100vh - 300px)', display: 'table', width: '100%'}}>
                <div className="about-01 sectionBg1" style={{display: 'table-cell', verticalAlign: 'middle', backgroundColor: 'white'}}>
                    <div className="container">
                    <div className="row col-mobile-top" style={{justifyContent: 'center'}}>


                            {/* Mobile */}
                            <div className="col-md-6 col-sm-12 prodiver2-mobile-1">


                                <div style={{ height: '50vh', width: '100%' }}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
                                                defaultCenter={defaultProps.center}
                                                defaultZoom={defaultProps.zoom}
                                            >
                                                <AnyReactComponent
                                                lat={32.776474}
                                                lng={-79.931053}
                                                text=""
                                                />
                                            </GoogleMapReact>
                                </div>
                            </div>


                            {/* Right Side */}

                            {contact && (
                            <div className="col-md-6 col-sm-12 col-mobile" style={{display: 'table'}}>
                                <div className="about-right" style={{display: 'table-cell', verticalAlign: 'middle'}}>
                                    <div className="horizontal-heading main-about" style={{color: '#000'}}>
                                    <h1 className='styled-heading2'>Contact Us</h1>
                                    
                                    
                                    <hr className='styled-divider'/>

                                    <p className="about-text" style={{marginLeft: '80px', marginRight: '80px'}}>


                                    <ReactMarkdown 
                                                children={contact.description}  
                                                remarkPlugins={[remarkGfm]}   
                                                /> 
                                    </p>
                                    
                                    
                                    <hr className='styled-divider'/>
                                    <p className="form-p-c" style={{marginTop: '20px', textAlign: 'center'}}>
                        


                                        {/* {social.facebookLink && ( */}
                                            <a 
                                            // href={social.facebookLink} 
                                            href='https://www.facebook.com/profile.php?id=61562861227627'                                             target='_blank'>
                                                <button className='btn btn-social-i-f2 btn-facebook2'>
                                                    <FaFacebookF/>
                                                </button>
                                            </a>
                                        {/* )} */}

                                        {/* {social.instagramLink && ( */}
                                            <a 
                                            // href={social.instagramLink} 
                                            href='https://www.instagram.com/persephoneyaesthetics?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr'                                             target='_blank'>
                                                <button className='btn btn-social-i btn-instagram2'>
                                                    <FaInstagram/>
                                                </button>
                                            </a>
                                        {/* )} */}

                                         {/* {social.tiktokLink && ( */}
                                            <a 
                                            // href={social.tiktokLink}
                                            href='https://www.tiktok.com/@persephoneyaesthetics?_t=8peu1JSSL0Q&_r=1'                                             target='_blank'>
                                                <button className='btn btn-social-i btn-tiktok2'>
                                                    <FaTiktok/>
                                                </button>
                                            </a>
                                        {/* )}  */}


            
                                        </p>
                                    <hr className='styled-divider'/>

                                    <hr className='show-break-style'/>
                                    <div className='template-p-detail'>
                                            {contact.footerText}
                                            <a
                                        className='email__design-s'
                                        href="mailto:persephoney88@gmail.com"
                                        target='_blank'> {contact.footerEmail}</a>
                                    </div>                          
                                    {/* <hr className='show-break-style'/>

                                    <div className='template-p-detail'>
                                            <a
                                        className='email__design-s'
                                        href={`tel:${contact.footerPhone}`}
                                        target='_blank'> {contact.footerPhone}</a>
                                    </div>    */}


                                    </div>

                       

                                </div>
                            </div>

                        )}
                            {/* Left Side */}
                            <div className="col-md-6 col-sm-12 prodiver2-mobile-2">


                                <div style={{ height: '50vh', width: '100%' }}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
                                                defaultCenter={defaultProps.center}
                                                defaultZoom={defaultProps.zoom}
                                            >
                                                <AnyReactComponent
                                                lat={32.776474}
                                                lng={-79.931053}
                                                text=""
                                                />
                                            </GoogleMapReact>
                                </div>
                            </div>
                            

                        </div>
                    </div>
                </div>



            </section>
            





            {/* <Portfolio/> */}

            {/* <Brands/> */}



            {/* <Services/> */}
            {/* <Statement/>
            <TextMedia/> */}

            {/* <About/> */}
            {/* <Stats/> */}
            {/* <TextMedia2/> */}
 
            <Statement2/>
        
            {/*<Media/>*/}
            {/*<Team/>*/}
            {/*<AddServices/>*/}


        </>
    );
}

export default Contact;