import './App.scss';
import './Responsive.css';

// import {Header, About, Brands, Stats, Portfolio, Services, Media, Team, AddServices} from './container';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import Homepage from "./pages/Homepage";
import { Footer } from './container';
import Navbar from './components/Navbar/Navbar';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import Membership from './pages/Membership';
import Gallery from './pages/Gallery';
import Services from './pages/Services';
import { Helmet } from 'react-helmet';
import AllServices from './pages/AllServices';
import Notice from './pages/Notice';
import Forms from './pages/Forms';



const App = () => {
  return (
    <div>
        <Helmet>
            <title>Persephoney Aesthetics</title>
            <meta
                name='description'
                content='Persephoney Aesthetics'
            />
            <meta name='keywords' content='Persephoney Aesthetics'/>
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="%PUBLIC_URL%/logo-dark.png"
            />
        </Helmet>

        <Navbar/>
        <Routes>
          <Route path='/' element={<Homepage/>} exact />
          <Route path='/providers' element={<AboutUs/>} />
          <Route path='/services' element={<AllServices/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/faq' element={<FAQ/>} />
          <Route path='/forms' element={<Forms/>} />
          <Route path='/notices' element={<Notice/>} />
          <Route path='/membership' element={<Membership/>} />
          <Route path='/gallery' element={<Gallery/>} />
          <Route path='/services/:slug' element={<Services/>} />
        </Routes>
        <Footer/>
    </div>
  );
}

export default App;
