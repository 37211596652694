// import './App.css';
// import './Responsive.css';
// import './loader';

import React, { useState, useEffect } from "react";
import { images } from "../constants";
import { IoIosArrowDown } from "react-icons/io";

import {
  Header,
  About,
  // Brands,
  // Stats,
  // Portfolio,
  // Services,
  // Media,
  // Team,
  // AddServices,
  Statement,
  Stats,
  Statement2,
  TextMedia,
  TextMedia2,
  // Footer
} from "../container";
// import About from '../container/About/About';
import videoBgMp4 from "../assets/medical.mp4";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, NavLink, useParams } from "react-router-dom";
import { FaWeightScale } from "react-icons/fa6";
import { FaRegEye, FaRegHandshake } from "react-icons/fa";
// import {Navbar} from '../components'

import { client, urlFor } from "../client";
import { Helmet } from "react-helmet";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import FacialTreatmentClientConsentForm from "../assets/Facial - Client Consent Form.pdf";
import FacialTreatmentForms from "../assets/Facial Treatment Forms.pdf";
import LEDTherapyForms from "../assets/LED Therapy Forms.pdf";
import HighFrequencyForms from "../assets/High Frequency Forms.pdf";
import MicroneedingForms from "../assets/Microdermabrasion Forms.pdf";
import MicrodermabrasionForms from "../assets/Microdermabrasion Forms.pdf";
import ChemicalPeelForms from "../assets/Chemical Peel Forms.pdf";
import NanofusionForms from "../assets/Nanofusion Forms.pdf";

const Services = () => {
  const [singleService, setSingleService] = useState(null);
  // const [services, setServices] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == "${slug}"]{
            name,
            _id,
            slug,
            description,
            serviceDescription,
            imageUrl,
            bookingLink,
            location1,
            location2,
            priceName1,
            priceName2,
            priceName3,
            priceName4,
            priceName5,
            priceName6,
            priceName7,
            priceName8,
            priceName9,
            priceName10,
            priceDescription1,
            priceDescription2,
            priceDescription3,
            priceDescription4,
            priceDescription5,
            priceDescription6,
            priceDescription7,
            priceDescription8,
            priceDescription9,
            priceDescription10,
            priceDescription11,
            priceDescription12,
            priceDescription13,
            priceDescription14,
            priceDescription15,
            price1,
            price2,
            price3,
            price4,
            price5,
            price6,
            price7,
            price8,
            price9,
            price10,
            price11,
            price12,
            price13,
            price14,
            price15
        }`
      )
      .then((data) => setSingleService(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!singleService)
    return (
      <div className="preloader">
        <div className="status"></div>
      </div>
    );

  return (
    <>
      <Helmet>
        <title>{singleService.name}</title>
        <meta name="description" content="Persephoney Aesthetics" />
        <meta name="keywords" content="Persephoney Aesthetics" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="%PUBLIC_URL%/logo-dark.png"
        />
      </Helmet>
      <section id="home-4">
        <video
          className="home-bg-video"
          src={videoBgMp4}
          autoPlay
          loop
          muted
          playsInline
        ></video>
        <div id="home-overlay-2"></div>
      </section>

      <div className="map-color-3">
        <div className="row" style={{ marginRight: "0" }}>
          <div className="home-headings tools-p-align">
            <div className="horizontal-heading influencer-name main-section-title">
              <h1 className="home-headings-2 influencer-name-h1">
                <span className="influencer-name-span">
                  {/* {about.name} */}
                  {singleService.name}
                </span>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <section
        id="about"
        className="main-section-content"
        style={{
          minHeight: "calc(100vh - 300px)",
          display: "table",
          width: "100%",
          paddingTop: "60px",
        }}
      >
        <div
          className="about-01 sectionBg1"
          style={{
            display: "table-cell",
            verticalAlign: "middle",
            backgroundColor: "white",
          }}
        >
          <div className="container">
            <div
              className="row col-mobile-top"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              {/* Left Side */}
              <div className="col-lg-6 col-md-6 col-sm-6" style={{}}>
                <div
                  className="mobile__hide-image column-show map-color-7 influencer-image"
                  style={{ border: "3px solid #dbc7ab" }}
                >
                  <div className="portfolio-item">
                    <img
                      src={urlFor(singleService.imageUrl).url()}
                      className="influencer-image-2"
                      alt=""
                    />
                    <div className="portfolio-item-overlay margin-top-g"></div>
                  </div>
                </div>
              </div>
              {/* Right Side */}
              <div
                className="col-md-6 col-sm-12 col-mobile"
                style={{ display: "table" }}
              >
                <div
                  className="about-right"
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                >
                  <div
                    className="horizontal-heading main-about"
                    style={{ color: "#000" }}
                  >
                    <h1 className="styled-heading2">{singleService.name}</h1>
                    <hr className="styled-divider" />
                    <div className="text-style2">
                      <p className="styled-p">
                        {/* {singleService.description} */}
                        <ReactMarkdown
                          children={singleService.serviceDescription}
                          remarkPlugins={[remarkGfm]}
                        />
                      </p>
                    </div>
                    <hr className="styled-divider" />

                    {singleService.location1 && (
                      <div className="home-btn" style={{ textAlign: "center" }}>
                        <a
                          className="btn btn-general btn-home smooth-scroll"
                          href="https://squareup.com/appointments/book/l0nnr0k3v7bz9d/7521ZXJDKHEBC/start"
                          // target="_blank"
                          //   rel="noreferrer"
                          style={{ textAlign: "center" }}
                        >
                          Book Now
                        </a>
                      </div>
                    )}

                    {singleService.location2 && (
                      <div className="home-btn" style={{ textAlign: "center" }}>
                        <a
                          className="btn btn-general btn-home smooth-scroll"
                          href="https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425"
                          // target="_blank"
                          //   rel="noreferrer"
                          style={{ textAlign: "center" }}
                        >
                          Book Now
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="about"
        style={{
          minHeight: "calc(100vh - 300px)",
          display: "table",
          width: "100%",
          paddingTop: "60px",
        }}
      >
        <div
          className="about-01 sectionBg1"
          style={{
            display: "table-cell",
            verticalAlign: "middle",
            backgroundColor: "#f8f4f4",
          }}
        >
          <div className="container">
            <div
              className="row col-mobile-top"
              style={{ justifyContent: "center" }}
            >
              <div className="col-md-12">
                <div className="tech-statement text-center">
                  <h1 className="styled-heading">
                    {singleService.name} Pricing
                  </h1>
                  <hr className="styled-divider" />

                  <table id="customers">
                    <tbody>
                      <tr>
                        <th>Services</th>
                        <th className="mobile-th-hide">Pricing</th>

                        {singleService.location1 && (
                        <th className="mobile-th-hide">Forms/Booking</th>
                        )}
                        
                        {singleService.location2 && (
                        <th className="mobile-th-hide">Booking</th>
                        )}
                      </tr>

                      {singleService.priceDescription1 && (
                        <tr>
                          <td className="services-padding">
                            {/* {singleService.priceDescription1} */}
                            <ReactMarkdown
                              children={singleService.priceDescription1}
                              remarkPlugins={[remarkGfm]}
                            />
                          </td>
                          <td className="services-padding">
                            <p className="services-price-p">
                              ${singleService.price1}
                            </p>
                          </td>
                          {/* <td>Germany</td> */}
                          <td className="services-padding">
                            {singleService.location1 && (
                              <div
                                className="home-btn moble-serice-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://book.squareup.com/appointments/l0nnr0k3v7bz9d/location/7521ZXJDKHEBC/services/3IPJOPBI3ZV76AF7WS4X5RNG"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book The Pawprint
                                </a>
                              </div>
                            )}

                            {singleService.location2 && (
                              <div
                                className="home-btn moble-serice-btn-3"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425"
                                  // target="_blank"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book Now
                                </a>
                              </div>
                            )}

                            {singleService.location1 && (
                              <>
                              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

                                <div className="home-btn moble-serice-btn-2">
                                  <NavLink
                                    className="btn btn-general btn-home smooth-scroll"
                                    to={FacialTreatmentForms}
                                    target="_blank"
                                  >
                                    Forms
                                  </NavLink>
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      )}

                      {singleService.priceDescription2 && (
                        <tr>
                          <td className="services-padding services-section-border-top">
                            <ReactMarkdown
                              children={singleService.priceDescription2}
                              remarkPlugins={[remarkGfm]}
                            />
                            {/* {singleService.priceDescription2} */}
                          </td>
                          <td className="services-padding">
                            <p className="services-price-p">
                              ${singleService.price2}
                            </p>
                          </td>
                          {/* <td>Germany</td> */}
                          <td className="services-padding">
                            {singleService.location1 && (
                              <div
                                className="home-btn moble-serice-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://book.squareup.com/appointments/l0nnr0k3v7bz9d/location/7521ZXJDKHEBC/services/U6R26PZK4YNZZIKPE2UC2IVP"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book MsTing
                                </a>
                              </div>
                            )}

                            {singleService.location2 && (
                              <div
                                className="home-btn moble-serice-btn-3"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425"
                                  // target="_blank"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book Now
                                </a>
                              </div>
                            )}

                            {singleService.location1 && (
                              <>
                              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

                                <div className="home-btn moble-serice-btn-2">
                                  <NavLink
                                    className="btn btn-general btn-home smooth-scroll"
                                    to={FacialTreatmentForms}
                                    target="_blank"
                                  >
                                    Forms
                                  </NavLink>
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      )}

                      {singleService.priceDescription3 && (
                        <tr>
                          <td className="services-padding services-section-border-top">
                            <ReactMarkdown
                              children={singleService.priceDescription3}
                              remarkPlugins={[remarkGfm]}
                            />
                            {/* {singleService.priceDescription3} */}
                          </td>
                          <td className="services-padding">
                            <p className="services-price-p">
                              ${singleService.price3}
                            </p>
                          </td>
                          <td className="services-padding">
                            {singleService.location1 && (
                              <div
                                className="home-btn moble-serice-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://book.squareup.com/appointments/l0nnr0k3v7bz9d/location/7521ZXJDKHEBC/services/UFGMRKBACHCETCZM4ZNNGP2A"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book Sonny
                                </a>
                              </div>
                            )}

                            {singleService.location2 && (
                              <div
                                className="home-btn moble-serice-btn-3"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425"
                                  // target="_blank"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book Now
                                </a>
                              </div>
                            )}

                            {singleService.location1 && (
                              <>
                              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

                                <div className="home-btn moble-serice-btn-2">
                                  <NavLink
                                    className="btn btn-general btn-home smooth-scroll"
                                    to={FacialTreatmentForms}
                                    target="_blank"
                                  >
                                    Forms
                                  </NavLink>
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      )}

                      {singleService.priceDescription4 && (
                        <tr>
                          <td className="services-padding services-section-border-top">
                            <ReactMarkdown
                              children={singleService.priceDescription4}
                              remarkPlugins={[remarkGfm]}
                            />
                            {/* {singleService.priceDescription4} */}
                          </td>
                          <td className="services-padding">
                          <p className="services-price-p">
                          ${singleService.price4}
                          </p>
                          
                          </td>
                          <td className="services-padding">
                            {singleService.location1 && (
                              <div
                                className="home-btn moble-serice-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://book.squareup.com/appointments/l0nnr0k3v7bz9d/location/7521ZXJDKHEBC/services/ATAT6GDTLTRRSDFGKWQFOL2Z"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book The Devine
                                </a>
                              </div>
                            )}

                            {singleService.location2 && (
                              <div
                                className="home-btn moble-serice-btn-3"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425"
                                  // target="_blank"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book Now
                                </a>
                              </div>
                            )}

                            {singleService.location1 && (
                              <>
                              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

                                <div className="home-btn moble-serice-btn-2">
                                  <NavLink
                                    className="btn btn-general btn-home smooth-scroll"
                                    to={FacialTreatmentForms}
                                    target="_blank"
                                  >
                                    Forms
                                  </NavLink>
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      )}

                      {singleService.priceDescription5 && (
                        <tr>
                          <td className="services-padding services-section-border-top">
                            <ReactMarkdown
                              children={singleService.priceDescription5}
                              remarkPlugins={[remarkGfm]}
                            />
                            {/* {singleService.priceDescription5} */}
                          </td>
                          <td className="services-padding">
                          <p className="services-price-p">

                          ${singleService.price5}
                          </p>
                          </td>
                          <td className="services-padding">
                            {singleService.location1 && (
                              <div
                                className="home-btn moble-serice-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://book.squareup.com/appointments/l0nnr0k3v7bz9d/location/7521ZXJDKHEBC/services/QMIKL7CIPZMSVEF6KMV25ADN"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book LED Therapy
                                </a>
                              </div>
                            )}

                            {singleService.location2 && (
                              <div
                                className="home-btn moble-serice-btn-3"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425"
                                  // target="_blank"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book Now
                                </a>
                              </div>
                            )}

                            {singleService.location1 && (
                              <>
                              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

                                <div className="home-btn moble-serice-btn-2">
                                  <NavLink
                                    className="btn btn-general btn-home smooth-scroll"
                                    to={LEDTherapyForms}
                                    target="_blank"
                                  >
                                    Forms
                                  </NavLink>
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      )}

                      {singleService.priceDescription6 && (
                        <tr>
                          <td className="services-padding services-section-border-top">
                            <ReactMarkdown
                              children={singleService.priceDescription6}
                              remarkPlugins={[remarkGfm]}
                            />
                            {/* {singleService.priceDescription6} */}
                          </td>
                          <td className="services-padding">
                          <p className="services-price-p">

                          ${singleService.price6}
                          </p>
                          </td>
                          <td className="services-padding">
                            {singleService.location1 && (
                              <div
                                className="home-btn moble-serice-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://book.squareup.com/appointments/l0nnr0k3v7bz9d/location/7521ZXJDKHEBC/services/VMBVGQS5MMJ5NSZOLZTX6Z3O"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book High Frequency
                                </a>
                              </div>
                            )}

                            {singleService.location2 && (
                              <div
                                className="home-btn moble-serice-btn-3"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425"
                                  // target="_blank"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book Now
                                </a>
                              </div>
                            )}

                            {singleService.location1 && (
                              <>
                              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

                                <div className="home-btn moble-serice-btn-2">
                                  <NavLink
                                    className="btn btn-general btn-home smooth-scroll"
                                    to={HighFrequencyForms}
                                    target="_blank"
                                  >
                                    Forms
                                  </NavLink>
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      )}

                      {singleService.priceDescription7 && (
                        <tr>
                          <td className="services-padding services-section-border-top">
                            <ReactMarkdown
                              children={singleService.priceDescription7}
                              remarkPlugins={[remarkGfm]}
                            />
                            {/* {singleService.priceDescription7} */}
                          </td>
                          <td className="services-padding">
                          <p className="services-price-p">

                          ${singleService.price7}
                          </p>
                          </td>
                          <td className="services-padding">
                            {singleService.location1 && (
                              <div
                                className="home-btn moble-serice-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://book.squareup.com/appointments/l0nnr0k3v7bz9d/location/7521ZXJDKHEBC/services/JVZHHWTSBNXFBFBUWECSZCBC"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book Microneeding
                                </a>
                              </div>
                            )}

                            {singleService.location2 && (
                              <div
                                className="home-btn moble-serice-btn-3"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425"
                                  // target="_blank"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book Now
                                </a>
                              </div>
                            )}

                            {singleService.location1 && (
                              <>
                              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

                                <div className="home-btn moble-serice-btn-2">
                                  <NavLink
                                    className="btn btn-general btn-home smooth-scroll"
                                    to={MicroneedingForms}
                                    target="_blank"
                                  >
                                    Forms
                                  </NavLink>
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      )}

                      {singleService.priceDescription8 && (
                        <tr>
                          <td className="services-padding services-section-border-top">
                            <ReactMarkdown
                              children={singleService.priceDescription8}
                              remarkPlugins={[remarkGfm]}
                            />
                            {/* {singleService.priceDescription8} */}
                          </td>
                          <td className="services-padding">
                          <p className="services-price-p">

                          ${singleService.price8}
                          </p>
                          </td>

                          <td className="services-padding">
                            {singleService.location1 && (
                              <div
                                className="home-btn moble-serice-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://book.squareup.com/appointments/l0nnr0k3v7bz9d/location/7521ZXJDKHEBC/services/AFKT4NMQXP2E5LQC5LFR6KQR"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book Microdermabrasion
                                </a>
                              </div>
                            )}

                            {singleService.location2 && (
                              <div
                                className="home-btn moble-serice-btn-3"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425"
                                  // target="_blank"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book Now
                                </a>
                              </div>
                            )}

                            {singleService.location1 && (
                              <>
                              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

                                <div className="home-btn moble-serice-btn-2">
                                  <NavLink
                                    className="btn btn-general btn-home smooth-scroll"
                                    to={MicrodermabrasionForms}
                                    target="_blank"
                                  >
                                    Forms
                                  </NavLink>
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      )}

                      {singleService.priceDescription9 && (
                        <tr>
                          <td className="services-padding services-section-border-top">
                            <ReactMarkdown
                              children={singleService.priceDescription9}
                              remarkPlugins={[remarkGfm]}
                            />
                            {/* {singleService.priceDescription9} */}
                          </td>
                          <td className="services-padding">
                          <p className="services-price-p">

                          ${singleService.price9}
                          </p>
                          </td>
                          <td className="services-padding">
                            {singleService.location1 && (
                              <div
                                className="home-btn moble-serice-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://book.squareup.com/appointments/l0nnr0k3v7bz9d/location/7521ZXJDKHEBC/services/XRHGULHBQTWG5HHAAOPKUGER"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book Chemical Peel
                                </a>
                              </div>
                            )}

                            {singleService.location2 && (
                              <div
                                className="home-btn moble-serice-btn-3"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425"
                                  // target="_blank"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book Now
                                </a>
                              </div>
                            )}

                            {singleService.location1 && (
                              <>
                              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

                                <div className="home-btn moble-serice-btn-2">
                                  <NavLink
                                    className="btn btn-general btn-home smooth-scroll"
                                    to={ChemicalPeelForms}
                                    target="_blank"
                                  >
                                    Forms
                                  </NavLink>
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      )}

                      {singleService.priceDescription10 && (
                        <tr>
                          <td className="services-padding services-section-border-top">
                            <ReactMarkdown
                              children={singleService.priceDescription10}
                              remarkPlugins={[remarkGfm]}
                            />
                            {/* {singleService.priceDescription10} */}
                          </td>
                          <td className="services-padding">
                          <p className="services-price-p">

                          ${singleService.price10}
                          </p>
                          </td>
                          <td className="services-padding">
                            {singleService.location1 && (
                              <div
                                className="home-btn moble-serice-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://book.squareup.com/appointments/l0nnr0k3v7bz9d/location/7521ZXJDKHEBC/services/K4KSY2E26IOFBT464EXM7ZEA"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book Nanofusion
                                </a>
                              </div>
                            )}

                            {singleService.location2 && (
                              <div
                                className="home-btn moble-serice-btn-3"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425"
                                  // target="_blank"
                                  //   rel="noreferrer"
                                  style={{ textAlign: "center" }}
                                >
                                  Book Now
                                </a>
                              </div>
                            )}

                            {singleService.location1 && (
                              <>
                              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

                                <div className="home-btn moble-serice-btn-2">
                                  <NavLink
                                    className="btn btn-general btn-home smooth-scroll"
                                    to={NanofusionForms}
                                    target="_blank"
                                  >
                                    Forms
                                  </NavLink>
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      )}





                      {/* {singleService.priceDescription11 && (
                        <tr>
                          <td className="services-padding">
                            <ReactMarkdown
                              children={singleService.priceDescription11}
                              remarkPlugins={[remarkGfm]}
                            />
                  
                          </td>
                          <td>
                          <p className="services-price-p">

                          ${singleService.price11}
                          </p>
                          </td>
                          <td className="services-padding">
                            {singleService.location1 && (
                              <div
                                className="home-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://book.squareup.com/appointments/l0nnr0k3v7bz9d/location/7521ZXJDKHEBC/services/3IPJOPBI3ZV76AF7WS4X5RNG"
                               
                                  style={{ textAlign: "center" }}
                                >
                                  Book Sonny
                                </a>
                              </div>
                            )}

                            {singleService.location2 && (
                              <div
                                className="home-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425"
                  
                                  style={{ textAlign: "center" }}
                                >
                                  Book Now
                                </a>
                              </div>
                            )}

                            {singleService.location1 && (
                              <>
                              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

                                <div className="home-btn">
                                  <NavLink
                                    className="btn btn-general btn-home smooth-scroll"
                                    to={FacialTreatmentClientConsentForm}
                                    target="_blank"
                                  >
                                    Forms
                                  </NavLink>
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      )} */}





                      {/* {singleService.priceDescription12 && (
                        <tr>
                          <td className="services-padding">
                            <ReactMarkdown
                              children={singleService.priceDescription12}
                              remarkPlugins={[remarkGfm]}
                            />
                    
                          </td>
                          <td>
                          <p className="services-price-p">

                          ${singleService.price12}
                          </p>
                          </td>
                          <td className="services-padding">
                            {singleService.location1 && (
                              <div
                                className="home-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://book.squareup.com/appointments/l0nnr0k3v7bz9d/location/7521ZXJDKHEBC/services/3IPJOPBI3ZV76AF7WS4X5RNG"
                 
                                  style={{ textAlign: "center" }}
                                >
                                  Book Sonny
                                </a>
                              </div>
                            )}

                            {singleService.location2 && (
                              <div
                                className="home-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425"
                     
                                  style={{ textAlign: "center" }}
                                >
                                  Book Now
                                </a>
                              </div>
                            )}

                            {singleService.location1 && (
                              <>
                              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

                                <div className="home-btn">
                                  <NavLink
                                    className="btn btn-general btn-home smooth-scroll"
                                    to={FacialTreatmentClientConsentForm}
                                    target="_blank"
                                  >
                                    Forms
                                  </NavLink>
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      )} */}






                      {/* {singleService.priceDescription13 && (
                        <tr>
                          <td className="services-padding">
                            <ReactMarkdown
                              children={singleService.priceDescription13}
                              remarkPlugins={[remarkGfm]}
                            />
                        
                          </td>
                          <td>
                          <p className="services-price-p">

                          ${singleService.price13}
                          </p>
                          </td>
                          <td className="services-padding">
                            {singleService.location1 && (
                              <div
                                className="home-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://book.squareup.com/appointments/l0nnr0k3v7bz9d/location/7521ZXJDKHEBC/services/3IPJOPBI3ZV76AF7WS4X5RNG"
                       
                                  style={{ textAlign: "center" }}
                                >
                                  Book Sonny
                                </a>
                              </div>
                            )}

                            {singleService.location2 && (
                              <div
                                className="home-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425"
                 
                                  style={{ textAlign: "center" }}
                                >
                                  Book Now
                                </a>
                              </div>
                            )}

                            {singleService.location1 && (
                              <>
                              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

                                <div className="home-btn">
                                  <NavLink
                                    className="btn btn-general btn-home smooth-scroll"
                                    to={FacialTreatmentClientConsentForm}
                                    target="_blank"
                                  >
                                    Forms
                                  </NavLink>
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      )} */}










                      {/* {singleService.priceDescription14 && (
                        <tr>
                          <td className="services-padding">
                            <ReactMarkdown
                              children={singleService.priceDescription14}
                              remarkPlugins={[remarkGfm]}
                            />
           
                          </td>
                          <td>
                          <p className="services-price-p">

                          ${singleService.price14}
                          </p>
                          </td>
                          <td className="services-padding">
                            {singleService.location1 && (
                              <div
                                className="home-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://book.squareup.com/appointments/l0nnr0k3v7bz9d/location/7521ZXJDKHEBC/services/3IPJOPBI3ZV76AF7WS4X5RNG"
                              
                                  style={{ textAlign: "center" }}
                                >
                                  Book Chemical Peel
                                </a>
                              </div>
                            )}

                            {singleService.location2 && (
                              <div
                                className="home-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425"

                                  style={{ textAlign: "center" }}
                                >
                                  Book Now
                                </a>
                              </div>
                            )}

                            {singleService.location1 && (
                              <>
                              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

                                <div className="home-btn">
                                  <NavLink
                                    className="btn btn-general btn-home smooth-scroll"
                                    to={FacialTreatmentClientConsentForm}
                                    target="_blank"
                                  >
                                    Forms
                                  </NavLink>
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      )} */}









                      {/* {singleService.priceDescription15 && (
                        <tr>
                          <td className="services-padding">
                            <ReactMarkdown
                              children={singleService.priceDescription15}
                              remarkPlugins={[remarkGfm]}
                            />
                      
                          </td>
                          <td>
                          <p className="services-price-p">

                            ${singleService.price15}
                          </p>
                          </td>
                          <td className="services-padding">

                            {singleService.location1 && (
                              <div
                                className="home-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://book.squareup.com/appointments/l0nnr0k3v7bz9d/location/7521ZXJDKHEBC/services/3IPJOPBI3ZV76AF7WS4X5RNG"
                       
                                  style={{ textAlign: "center" }}
                                >
                                  Book Nanofusion
                                </a>
                              </div>
                            )}

                            {singleService.location2 && (
                              <div
                                className="home-btn"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  className="btn btn-general btn-home smooth-scroll"
                                  href="https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425"
                  
                                  style={{ textAlign: "center" }}
                                >
                                  Book Now
                                </a>
                              </div>
                            )}

                            {singleService.location1 && (
                              <>
                              <hr className="styled-divider2" style={{ margin: "30px auto" }} />

                                <div className="home-btn">
                                  <NavLink
                                    className="btn btn-general btn-home smooth-scroll"
                                    to={FacialTreatmentClientConsentForm}
                                    target="_blank"
                                  >
                                    Forms
                                  </NavLink>
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      )} */}





                    </tbody>
                  </table>

                  <hr className="styled-divider" />
                  {/* <div className='text-style'>
                                    <p className='styled-p' style={{fontWeight: '800'}}>Example Question? </p>
                                    <p className='styled-p'>Example Answer</p>

                                    </div>
                                    <hr className='styled-divider'/>
                                    <div className='text-style'>
                                    <p className='styled-p' style={{fontWeight: '800'}}>Example Question? </p>
                                    <p className='styled-p'>Example Answer</p>

                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Portfolio/> */}

      {/* <Brands/> */}

      {/* <Services/> */}
      {/* <Statement/>
            <TextMedia/> */}

      <About />
      {/* <Stats/> */}
      {/* <TextMedia2/> */}

      <Statement2 />

      {/*<Media/>*/}
      {/*<Team/>*/}
      {/*<AddServices/>*/}
    </>
  );
};

export default Services;
