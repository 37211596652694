// import './App.css';
// import './Responsive.css';
// import './loader';

import React, { useEffect, useState } from 'react';
import {images} from "../constants";
import { IoIosArrowDown } from 'react-icons/io';
import { client } from '../client';

import {
    Header,
    About,
    // Brands,
    // Stats,
    // Portfolio,
    // Services,
    // Media,
    // Team,
    // AddServices,
    Statement,
    Stats,
    Statement2,
    TextMedia,
    TextMedia2,
    // Footer
} from '../container';
// import About from '../container/About/About';
import videoBgMp4 from '../assets/medical.mp4';

// import {Navbar} from '../components'



import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FaRegEye, FaRegHandshake } from 'react-icons/fa';
import { FaWeightScale } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Services from './Services';




const AllServices = () => {
    const [membership, setMembership] = useState(null);

    useEffect(() => {
        client.fetch(`*[_type == "membership"]|order(orderRank)`
        ).then((data) => {
            setMembership(data)
        })

    }, []);

    if(!membership) return (
        <div className="preloader">
            <div className="status"></div>
        </div>
    )

    return (
        <>
         <Helmet>
            <title>Membership</title>
            <meta
                name='description'
                content='Persephoney Aesthetics'
            />
            <meta name='keywords' content='Persephoney Aesthetics'/>
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="%PUBLIC_URL%/logo-dark.png"
            />
        </Helmet>
            <section id="home-4">
                <video className="home-bg-video" src={videoBgMp4} autoPlay loop muted playsInline>
                </video>
                <div id="home-overlay-2"></div>
            </section>

            <div className="map-color-3" >
                <div className="row" style={{marginRight: '0'}}>
                    <div className="home-headings tools-p-align">
                        <div className="horizontal-heading influencer-name main-section-title">
                            <h1 className="home-headings-2 influencer-name-h1"><span className='influencer-name-span'> 
                            {/* {about.name} */}
                            Services
                            </span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <section id="imageBackground" className='mobileServices'>

            

            {/* About 02 */}

            </section>

            


            {/* <Portfolio/> */}

            {/* <Brands/> */}



            {/* <Services/> */}
            {/* <Statement/>
            <TextMedia/> */}

            <About/>
            {/* <Stats/> */}
            {/* <TextMedia2/> */}
 
            <Statement2/>
        
            {/*<Media/>*/}
            {/*<Team/>*/}
            {/*<AddServices/>*/}


        </>
    );
}

export default AllServices;




