import React, { useEffect, useState } from 'react';
import './Statement.scss';
import {FaQuoteLeft, FaQuoteRight} from "react-icons/fa";
import { images } from '../../constants';
import { IoIosArrowDown } from 'react-icons/io';

import {urlFor, client} from "../../client";

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'


const Statement = () => {
    const [about, setAbout] = useState([]);

    useEffect(() => {
        client.fetch(`*[_type == "homepage"] | order(orderRank) {
            tagline,
            _id,
            hpbuttonLink,
            hpbuttonText,
            boxheading,
            boxtext,
            boxLink,
            boxheading2,
            boxtext2,
            boxLink2,
            boxheading3,
            boxtext3,
            boxLink3,
            secPreheading,
            secHeading,
            secDescription,
            secLink,
            secLinkText,
            secSubheading,
            secSubHeading2,
            sec2Preheading,
            sec2Heading,
            sec2Description,
            sec2Link,
            sec2LinkText,
            sec2Subheading,
            sec2SubHeading2,
            sec3Preheading,
            sec3Heading,
            sec3Description,
            sec3Link,
            sec3LinkText,
            sec3Subheading,
            sec3SubHeading2,
            statement,
            footerPreheading,
            footerHeading,
            footerDescription,
            footerCopyriight
        }`).then((data) => setAbout(data[0]))
            .catch(console.error)
    }, []);

    if(!about) return (
        <div className="preloader">
            <div className="status"></div>
        </div>
    )



    return (
        <>


        <section id="statement" style={{scrollMarginTop: '2rem'}}>
            <div className="content-box-sm" style={{backgroundColor: "#f8f4f4"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tech-statement text-center home-statement-mobile">
                            
                                <h1 className='styled-heading'>{about.secHeading}</h1>
                                <h5 style={{color: '#563E1E',
    fontWeight: '800'}}>{about.secPreheading}</h5>
                                <hr className='styled-divider'/>
                                    <div className='text-style'>
                                        <p className='styled-p'>
                                            <ReactMarkdown 
                                                children={about.secDescription} 
                                                remarkPlugins={[remarkGfm]}   
                                            />
                                        </p>



                                        <div class="basic-card basic-card-aqua " style={{margin: '0 auto'}}>
                                            {about.secLink && (
                                                <div className="home-btn">
                                                    <a className="btn btn-general btn-home smooth-scroll" href={about.secLink} title={about.secLinkText} role="button">{about.secLinkText}</a>
                                                </div>
                                            )}
                                        </div>

                                    {/* <p className='styled-p'>Combining proven methods, quality products, advanced devices, and a highly professional and experienced team, Persephoney Aesthetics is your best choice for a most relaxing and rejuvenating experience.</p>
                                        <h4>Join us now, you deserve it</h4> */}
                                    </div>
                                <hr className='styled-divider'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>




            </>
    );
};

export default Statement;