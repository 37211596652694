import React, { useState, useEffect } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { images } from "../../constants";
import "./Navbar.scss";
import { Link, NavLink } from "react-router-dom";
import { client } from "../../client";



const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  // setting initial value to true
  const [scroll, setScroll] = useState(false);


  const [services, setServices] = useState(null);

  useEffect(() => {
      client.fetch(`*[_type == "services"]|order(orderRank)`
      ).then((data) => {
          setServices(data)
      })

  }, []);


  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);


  if(!services) return (
    <div className="preloader">
        <div className="status"></div>
    </div>
)

  return (
    <nav
      className={
        scroll
          ? "app__navbar app__navbar-scroll"
          : "app__navbar app__navbar-scroll"
      }
    >
     
      <div className="app__navbar-logo">
      <NavLink to="/">
        <img src={scroll ? images.logoBrown2 : images.logoBrown2} alt="logo" />
        </NavLink>
      </div>
    
      <ul className="app__navbar-links nav-link-test">
        <li className="app__flex p-text">
          <div />
          <NavLink
            className={
              scroll
                ? "navbar__links-styles-scroll navbar__links-color-scroll "
                : "navbar__links-styles-scroll navbar__links-color-scroll"
            }
            to="/"
          >
            Home
          </NavLink>
        </li>
        <li className="app__flex p-text dropdown">
          <div />
          <a
            href="/services"
            className={
              scroll
                ? "navbar__links-styles-scroll navbar__links-color-scroll"
                : "navbar__links-styles-scroll navbar__links-color-scroll"
            }
            to=""
          >
            Our Services
            <i class="fa fa-caret-down"></i>
          </a>
          <div class="dropdown-content" >
          {services.map((service, index) => ( 
            <Link 
            key={index}
            to={'/services/' + service.slug.current}
            style={{borderLeft: '2px solid #dbc7ab', borderTop: '1px solid #dbc7ab', borderBottom: '1px solid #dbc7ab', background: '#fff'}}>
            {service.name}
            </Link>

          ))}

         </div>
        </li>
        {/* <li className="app__flex p-text">
          <div />
          <NavLink
            className={
              scroll
                ? "navbar__links-styles-scroll navbar__links-color-scroll"
                : "navbar__links-styles-scroll navbar__links-color-scroll"
            }
            to="/membership"
          >
            Membership
          </NavLink>
        </li> */}
        <li className="app__flex p-text">
          <div />
          <NavLink
            className={
              scroll
                ? "navbar__links-styles-scroll navbar__links-color-scroll"
                : "navbar__links-styles-scroll navbar__links-color-scroll"
            }
            to="/faq"
          >
            FAQ
          </NavLink>
        </li>

        {/* <li className="app__flex p-text">
          <div />
          <NavLink
            className={
              scroll
                ? "navbar__links-styles-scroll navbar__links-color-scroll"
                : "navbar__links-styles-scroll navbar__links-color-scroll"
            }
            to="/gallery"
          >
            Gallery
          </NavLink>
        </li> */}
        <li className="app__flex p-text">
          <div />
          <NavLink
            className={
              scroll
                ? "navbar__links-styles-scroll navbar__links-color-scroll"
                : "navbar__links-styles-scroll navbar__links-color-scroll"
            }
            to="/providers"
          >
            Providers
          </NavLink>
        </li>

        <li className="app__flex p-text">
          <div />
          <NavLink
            className={
              scroll
                ? "navbar__links-styles-scroll navbar__links-color-scroll"
                : "navbar__links-styles-scroll navbar__links-color-scroll"
            }
            to="/contact"
          >
            Contact
          </NavLink>
        </li>
        <li className="app__flex p-text">
          <div />
          <NavLink
            className={
              scroll
                ? "navbar__links-styles-scroll navbar__links-color-scroll"
                : "navbar__links-styles-scroll navbar__links-color-scroll"
            }
            to="/forms"
          >
            Forms
          </NavLink>
        </li>

        <li className="app__flex p-text">
          <div />
          <NavLink
            className={
              scroll
                ? "navbar__links-styles-scroll navbar__links-color-scroll"
                : "navbar__links-styles-scroll navbar__links-color-scroll"
            }
            to="/notices"
          >
            Notices
          </NavLink>
        </li>
        <li className="app__flex p-text dropdown">
          <div />
          <a
            href="https://squareup.com/appointments/book/l0nnr0k3v7bz9d/7521ZXJDKHEBC/start"
            // target="_blank"
            className={
              scroll
                ? "navbar__links-styles-scroll navbar__links-color-scroll"
                : "navbar__links-styles-scroll navbar__links-color-scroll"
            }
          >
            Book Now
          </a>
          <div class="dropdown-content" >

            <a style={{background: '#fff', borderTop: '2px solid #dbc7ab', borderBottom: '2px solid #dbc7ab' }} 
            href="https://squareup.com/appointments/book/l0nnr0k3v7bz9d/7521ZXJDKHEBC/start"
            // target="_blank"
            >
            Alameda Location
            </a>
            <a 
            style={{background: '#fff', borderBottom: '2px solid #dbc7ab'}} 
            href='https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425'
            // target="_blank"
            >
            South Carolina Location
            </a>

         </div>
        </li>


        {/* <li className="app__flex p-text">
          <div />
          <NavLink
            className={
              scroll
                ? "navbar__links-styles-scroll navbar__links-color-scroll"
                : "navbar__links-styles-scroll navbar__links-color-scroll"
            }
            to="/contact"
          >
            Contact
          </NavLink>
        </li> */}

        {/* {['home','Our Services','Pricing' ,'Membership','FAQ','Gallery','About Us','Contact','Book Now'].map((item)=> (
                    <li className='app__flex p-text' key={`link-${item}`}>
                        <div/>
                        <a className={scroll ? 'navbar__links-color-scroll' : 'navbar__links-color-scroll'} href={`#${item}`}>{item}</a>
                    </li>
                ))} */}
      </ul>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <div
          // whileInView={{x: [300, 0]}}
          // transition={{duration: 0.85, ease: 'easeOut'}}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {/*{['home','about','work','skills','contact'].map((item)=> (*/}
              {/*    <li key={item}>*/}
              {/*        <a href={`#${item}`} onClick={() => setToggle(false)}>{item}</a>*/}
              {/*    </li>*/}
              {/*))}*/}
              <li>
                <NavLink
                  className="app__navbar-menu-links nav-link-test"
                  to="/"
                  onClick={() => setToggle(false)}
                >
                  Home
                </NavLink>
              </li>

              {services.map((service, index) => ( 

              <li
              key={index}
              >
                <NavLink
                  className="app__navbar-menu-links"
                  to={'/services/' + service.slug.current}
                  onClick={() => setToggle(false)}
                >
                  {service.name}
                </NavLink>
              </li>




          ))}



              <li>
                <NavLink
                  className="app__navbar-menu-links"
                  to="/faq"
                  onClick={() => setToggle(false)}
                >
                  FAQ
                </NavLink>
              </li>

              <li>
                <NavLink
                  className="app__navbar-menu-links"
                  to="/providers"
                  onClick={() => setToggle(false)}
                >
                  Providers
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="app__navbar-menu-links"
                  to="/contact"
                  onClick={() => setToggle(false)}
                >
                  Contact
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="app__navbar-menu-links"
                  to="/forms"
                  onClick={() => setToggle(false)}
                >
                  Forms
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="app__navbar-menu-links"
                  to="/notices"
                  onClick={() => setToggle(false)}
                >
                  Notices
                </NavLink>
              </li>
              <li>
                <a
                  className="app__navbar-menu-links"
                  href="https://squareup.com/appointments/book/l0nnr0k3v7bz9d/7521ZXJDKHEBC/start"
                  onClick={() => setToggle(false)}
                  // target="_blank"
                >
                  Book Alameda
                </a>
              </li>
              <li>
                <a
                  className="app__navbar-menu-links"
                  href='https://www.tebra.com/care/provider/kendrea-robinson-aprn-1861052425'
                  onClick={() => setToggle(false)}
                  // target="_blank"
                >
                  Book South Carolina
                </a>
              </li>

            </ul>
          </div>
        )}
      </div>
    </nav>

    // <header>
    //     <nav className={scroll ? "navbar navbar-fixed-top mobile-nav-top" : "navbar navbar-fixed-top mobile-nav-top white-nav-top"}>
    //         <div className="container-fluid">
    //             <div className="site-nav-wrapper">
    //                 <div className="navbar-header">
    //                     <span id="mobile-nav-open-btn">&#9776;</span>
    //
    //                     <a className="navbar-brand smooth-scroll logo-rsz" href="#home">
    //                         <img src="img/kt_logo/new_logo/knight-team-management-logo-dark.png" className={scroll ? "img-rsz" : "img-rsz"}
    //                              alt="logo"/>
    //                     </a>
    //                 </div>
    //                 <div className="container">
    //                     <div className="collapse navbar-collapse">
    //                         <ul className="nav navbar-nav pull-right">
    //                             <li><a className="smooth-scroll ipad-m" href="#home">Home</a></li>
    //                             <li><a className="smooth-scroll ipad-m" href="#about">About</a></li>
    //                             <li><a className="smooth-scroll ipad-m" href="#brands">Brands</a></li>
    //                             <li><a className="smooth-scroll ipad-m" href="#portfolio">Influencers</a></li>
    //                             <li><a className="smooth-scroll ipad-m" href="#offers">What We Offer</a></li>
    //                             <li><a className="smooth-scroll ipad-m" href="#services">Video/Media</a></li>
    //                             <li><a className="smooth-scroll ipad-m" href="#team">Team</a></li>
    //                             <li><a className="smooth-scroll ipad-m" href="#portfolio-2">Addl. Services</a></li>
    //                             <li><a className="smooth-scroll ipad-m" href="#contact">Contact</a></li>
    //                         </ul>
    //                     </div>
    //                 </div>
    //
    //                 <div id="mobile-nav">
    //                     <span id="mobile-nav-close-btn">&times;</span>
    //                     <div id="mobile-nav-content">
    //                         <ul className="nav">
    //                             <li><a className="smooth-scroll ipad-m" href="#home">Home</a></li>
    //                             <li><a className="smooth-scroll ipad-m" href="#about">About</a></li>
    //                             <li><a className="smooth-scroll ipad-m" href="#brands">Brands</a></li>
    //                             <li><a className="smooth-scroll ipad-m" href="#portfolio">Influencers</a></li>
    //                             <li><a className="smooth-scroll ipad-m" href="#offers">What We Offer</a></li>
    //                             <li><a className="smooth-scroll ipad-m" href="#services">Video/Media</a></li>
    //                             <li><a className="smooth-scroll ipad-m" href="#team">Team</a></li>
    //                             <li><a className="smooth-scroll ipad-m" href="#portfolio-2">Addl. Services</a></li>
    //                             <li><a className="smooth-scroll ipad-m" href="#contact">Contact</a></li>
    //                         </ul>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </nav>
    // </header>
  );
};

export default Navbar;
