import logo2 from '../assets/logo-light2.png';
import logo1 from '../assets/logo-dark.png';
import logo3 from '../assets/logo-gold.png';
import logo4 from '../assets/logo-mix.png';
import logo5 from '../assets/logo-mix2.png';
import logo6 from '../assets/logo-mix3.png';
import logo7 from '../assets/logo-mix4.png';
import logo8 from '../assets/logo-mix5.png';
import logo9 from '../assets/logo-mix6.png';
import logo10 from '../assets/logo-mix7.png';
import logo11 from '../assets/logo-mix8.png';
import logoDark from '../assets/logo1.png';
import logoBrown from '../assets/logo6.png';
import logoBrown2 from '../assets/logo7.png';
import logoLight from '../assets/logo2.png';
import medical from '../assets/medical.jpg';
import gallery1 from '../assets/gallery1.jpg';
import gallery2 from '../assets/gallery2.jpg';
import gallery3 from '../assets/gallery3.jpg';
import gallery4 from '../assets/gallery4.jpg';
import gallery5 from '../assets/gallery5.jpg';

export default {
    logo2,
    logo1,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11,
    logoDark,
    logoLight,
    logoBrown,
    logoBrown2,
    medical,
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
}